<template>
  <div>
    <b-form
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >

      <!-- Row Loop -->
      <b-row
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
      >

        <!-- Item Name -->
        <b-col md="12">
          <b-row>
            <b-col>
              <b-form-group
                :label="`Day ${index+1}`"
                :label-for="'day-'+item.id"
              >
                <b-form-datepicker
                  :id="'day-'+item.id"
                  v-model="item.date"
                  append-to-body
                  dropright
                  reset-button
                  @change="notifyParent"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="`Title`"
                :label-for="'title-'+item.id"
              >
                <b-form-input
                  :id="'title-'+item.id"
                  v-model="item.title"
                  placeholder="Title"
                  required
                  type="text"
                  @change="notifyParent"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="items.length > 1"
              cols="auto"
            ><b-button

              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              class="mt-0 mt-md-2 btn-icon"
              variant="outline-danger"
              @click="removeItem(index)"
            >
              <feather-icon
                class="mr-25"
                icon="XIcon"
              />
              <span>Remove Day</span>
            </b-button></b-col>

          </b-row>
        </b-col>
        <b-col
          md="12"
        >
          <b-row
            v-for="(slot, index) in item.events_agendatables.data"
            :key="slot.id"
            class="ml-25 border-left my-1 py-1 pr-1 bg-light rounded"
          >
            <b-col
              md="12"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    :label="`Agenda ${index+1}`"
                    :label-for="'agenda-'+item.id+slot.id"
                  >
                    <b-form-input
                      :id="'agenda-'+item.id+slot.id"
                      v-model="slot.title"
                      placeholder="Agenda"
                      required
                      type="text"
                      @change="notifyParent"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.events_agendatables.data.length > 1"
                  class="col-auto d-flex align-items-end pb-1"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    @click="item.events_agendatables.data.splice(index, 1)"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="XIcon"
                    />
                    <span>Remove</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label-for="'begin_time-'+item.id+slot.id"
                label="Begin Time"
              >
                <b-form-timepicker
                  :id="'begin_time-'+item.id+slot.id"
                  v-model="slot.begin_time"
                  placeholder="Begin Time"
                  required
                  reset-button
                  @change="notifyParent"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label-for="'end_time-'+item.id+slot.id"
                label="End Time"
              >
                <b-form-timepicker
                  :id="'end_time-'+item.id+slot.id"
                  v-model="slot.end_time"
                  placeholder="End Time"
                  required
                  reset-button
                  @change="notifyParent"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-row
                v-for="(objective, index) in slot.events_objectivestables.data"
                :key="objective.id"

                class="ml-25 border-left"
              >
                <b-col>
                  <b-form-group
                    :label="`Objective ${index+1}`"
                    :label-for="'objective-'+item.id+slot.id+objective.id"
                  >
                    <b-form-input
                      :id="'objective-'+item.id+slot.id+objective.id"
                      v-model="objective.title"
                      placeholder="Objective"
                      required
                      type="text"
                      @change="notifyParent"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="col-auto d-flex align-items-end pb-1">
                  <b-button
                    v-if="index === slot.events_objectivestables.data.length - 1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :class="{'mr-2':slot.events_objectivestables.data.length > 1}"
                    :disabled="!objective.title"
                    variant="primary"
                    @click="slot.events_objectivestables.data.push(
                      {
                        id: Date.now().toString().substring(0, 10),
                        title: '',
                        events_participantsassessments: {data: [
                          {
                            id: Date.now().toString().substring(0, 10),
                            question: '',
                            data_type: '',
                          },
                        ]},
                      })"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="PlusIcon"
                    />
                    <span>Add Objective</span>
                  </b-button>
                  <b-button
                    v-if="slot.events_objectivestables.data.length > 1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    @click="slot.events_objectivestables.data.splice(index, 1)"
                  >
                    <feather-icon
                      class="mr-25"
                      icon="XIcon"
                    />
                    <span>Remove</span>
                  </b-button>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-row
                    v-for="(evaluation, index) in objective.events_participantsassessments.data"
                    :id="evaluation.id"
                    :key="evaluation.id"
                    class="border-left ml-25"
                  >
                    <!-- Item Name -->
                    <b-col md="6">
                      <b-form-group
                        :label="`Question ${index+1}`"
                        :label-for="'question-'+evaluation.id"
                      >
                        <b-form-input
                          :id="'question-'+evaluation.id"
                          v-model="evaluation.question"
                          placeholder="Question"
                          required
                          type="text"
                          @change="notifyParent"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Answer -->
                    <b-col>
                      <b-form-group
                        :label-for="'data_type-'+evaluation.id"
                        label="Data Type"
                      >
                        <v-select
                          id="'data_type-'+item.id"
                          v-model="evaluation.data_type"
                          :options="['Text',
                                     'Number',
                                     'Email',
                                     'Website URL',
                                     'Upload File',]"
                          append-to-body
                          label="Data type"
                          placeholder="Select from list"
                          @change="notifyParent"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col class="col-auto d-flex align-items-end pb-1">
                      <b-button
                        v-if="index === objective.events_participantsassessments.data.length - 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :class="{'mr-2': objective.events_participantsassessments.data.length > 1}"
                        :disabled="!evaluation.question || !evaluation.data_type"
                        variant="outline-primary"
                        @click="objective.events_participantsassessments.data.push({
                          id: Date.now().toString().substring(0, 10),
                          question: '',
                          data_type: '',
                        })"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="PlusIcon"
                        />
                        <span>Add Question</span>
                      </b-button>
                      <b-button
                        v-if="objective.events_participantsassessments.data.length > 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-danger"
                        @click="objective.events_participantsassessments.data.splice(index, 1)"
                      >
                        <feather-icon
                          class="mr-25"
                          icon="XIcon"
                        />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1"
            variant="primary"
            @click="item.events_agendatables.data.push(
              {
                id: Date.now().toString().substring(0, 10),
                title: '',
                begin_time: '',
                end_time: '',
                events_objectivestables: {data:[
                  {
                    id: Date.now().toString().substring(0, 10),
                    title: '',
                    events_participantsassessments: {data: [
                      {
                        id: Date.now().toString().substring(0, 10),
                        question: '',
                        data_type: '',
                      },
                    ]},
                  },
                ]},
              })"
          >
            <feather-icon
              class="mr-25"
              icon="PlusIcon"
            />
            <span>Add Agenda</span>
          </b-button>
        </b-col>

        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>

    </b-form>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        class="mr-25"
        icon="PlusIcon"
      />
      <span>Add Day</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormTimepicker, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: Date.now().toString().substring(0, 10),
        title: '',
        date: '',
        events_agendatables: {
          data: [{
            id: Date.now().toString().substring(0, 10),
            begin_time: '',
            end_time: '',
            title: '',
            events_objectivestables: {
              data: [
                {
                  id: Date.now().toString().substring(0, 10),
                  title: '',
                  events_participantsassessments: {
                    data:
                      [
                        {
                          id: Date.now().toString().substring(0, 10),
                          question: '',
                          data_type: '',
                        },
                      ],
                  },
                },
              ],
            },
          }],
        },
      }],
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.addDayNumber(this.items))
    },
    addDayNumber(arr) {
      arr.map((e, index) => Object.assign(e, { day_number: (index + 1).toString() }))
      return arr
    },
    repeateAgain() {
      this.items.push({
        id: Date.now().toString().substring(0, 10),
        title: '',
        date: '',
        events_agendatables: {
          data: [{
            id: Date.now().toString().substring(0, 10),
            begin_time: '',
            end_time: '',
            title: '',
            events_objectivestables: {
              data: [
                {
                  id: Date.now().toString().substring(0, 10),
                  title: '',
                  events_participantsassessments: {
                    data:
                        [
                          {
                            id: Date.now().toString().substring(0, 10),
                            question: '',
                            data_type: '',
                          },
                        ],
                  },
                },
              ],
            },
          }],
        },
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
