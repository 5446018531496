<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          @on-complete="formSubmitted"
        >

          <!-- Basic Info tab -->
          <tab-content
            title="Basic Information"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Basic Information
                  </h5>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Event Type"
                    label-for="eventType"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Event Type"
                      rules=""
                    >
                      <v-select
                        id="eventType"
                        v-model="eventType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['Workshop',
                                   'Networking session',
                                   'Mentorship session',
                                   'Ideathon',
                                   'Demo Day',
                                   'Pitching session']"
                        label="Event Type"
                        placeholder="Select from list or type to Add new"
                        push-tags
                        taggable
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Event Name"
                    label-for="eventName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Event Name"
                      rules=""
                    >
                      <b-form-input
                        id="eventName"
                        v-model="eventName"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Meeting Room, Internet Credits, AWS Credits etc."
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Event Organizers"
                    label-for="organizers"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Organizers"
                      rules=""
                    >
                      <v-select
                        id="organizers"
                        v-model="organizers"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['Inhouse',
                                   'Import from networks',
                                   'Import from Startups']"
                        label="Event Type"
                        placeholder="Select an organizer"
                      />
                      <small
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Event Partners"
                    label-for="eventPartners"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="eventPartners"
                      rules=""
                    >

                      <v-select
                        id="eventPartners"
                        v-model="eventPartners"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="[
                          'Import from networks',
                          'Import from Startups']"
                        label="Event Partners"
                        placeholder="Select event partners"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Target Participants"
                    label-for="targetParticipants"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="targetParticipants"
                      rules=""
                    >
                      <v-select
                        id="targetParticipants"
                        v-model="targetParticipants"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="[
                          'Import from networks',
                          'Import from Startups']"
                        label="Target Participants"
                        placeholder="Select target participants"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Price per Participants"
                    label-for="price"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Price per Participants"
                      rules=""
                    ><b-input-group prepend="₹">
                       <b-form-input
                         id="price"
                         v-model="price"
                         placeholder="Price per Participants"
                         type="number"
                       /></b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Total Capacity"
                    label-for="capacity"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Total Capacity"
                      rules=""
                    >
                      <b-form-input
                        id="capacity"
                        v-model="capacity"
                        placeholder="Total Capacity"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Event Group tab -->
          <tab-content
            title="Event Schedule"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Event Schedule
                  </h5>
                </b-col>
                <b-col md="12">
                  <schedule-form v-model="scheduleForm" />
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Application Form tab  -->
          <tab-content
            title="Application Form"
          >
            <validation-observer
              ref="addressRules"
              tag="form"
            >
              <b-row>
                <b-col
                  class="mb-2"
                  cols="12"
                >
                  <h5 class="mb-0">
                    Application Form
                  </h5>
                </b-col>
                <b-col md="12">
                  <application-form v-model="applicationForm" />
                </b-col>
                <b-col md="12">
                  <hr>
                  <b-form-checkbox
                    v-model="screening"
                    checked="true"
                    inline
                    name="check-button"
                    switch
                  >
                    Create a screening form?
                  </b-form-checkbox>
                  <hr>
                  <application-form
                    v-if="screening"
                    v-model="screeningForm"
                  />
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol, BFormGroup, BFormInput, BInputGroup, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import ApplicationForm from './components/ApplicationForm.vue'
import ScheduleForm from './components/ScheduleForm.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    BFormCheckbox,
    ApplicationForm,
    ScheduleForm,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      eventName: '',
      eventType: '',
      organizers: '',
      eventPartners: '',
      targetParticipants: '',
      price: '',
      capacity: '',
      scheduleForm: [],
      applicationForm: [],
      screeningForm: [],
      screening: false,
      required,
      email,
    }
  },
  methods: {
    formSubmitted() {
      this.$apollo.mutate({
        mutation: gql`mutation (
          $events_scheduletables: [events_scheduletable_insert_input!]!, $events_applicationtables:  [events_applicationtable_insert_input!]!, $events_applicationscreeningtables: [events_applicationscreeningtable_insert_input!]!
        ) {
          insert_events_basicinfo_one(object: {
            event_name: "${this.eventName}"
            event_type: "${this.eventType}"
            capacity: "${this.capacity}"
            price: "${this.price}"
            events_scheduletables: {data: $events_scheduletables}
            events_applicationtables: {data:$events_applicationtables}
            events_applicationscreeningtables: {data: $events_applicationscreeningtables}
            }) {
              id
            }
        }`,
        variables: {
          events_scheduletables: this.scheduleForm,
          events_applicationtables: this.applicationForm,
          events_applicationscreeningtables: this.screeningForm,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
