<template>
  <div>
    <b-form

      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >

      <!-- Row Loop -->
      <b-row
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
        ref="row"
      >

        <!-- Item Name -->
        <b-col>
          <b-form-group
            :label="`Question ${index+1}`"
            :label-for="'faq-question-'+item.id"
          >
            <b-form-input
              :id="'faq-question-'+item.id"
              v-model="item.question"
              type="text"
              placeholder="Question"
              required
              @change="notifyParent"
            />
          </b-form-group>
        </b-col>

        <!-- Answer -->
        <b-col md="3">
          <b-form-group
            label="Data Type"
            :label-for="'faq-dataType-'+item.id"
          >
            <v-select
              id="'faq-dataType-'+item.id"
              v-model="item.data_type"
              append-to-body
              :options="['Text',
                         'Number',
                         'Email',
                         'Website URL',
                         'Upload File',]"
              label="Data type"
              placeholder="Select from list"
              @change="notifyParent"
            />
          </b-form-group>
        </b-col>

        <!-- Remove Button -->
        <b-col
          v-if="items.length > 1"
          md="auto"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Delete</span>
          </b-button>
        </b-col>
      </b-row>

    </b-form>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class=" mt-1"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{
        id: Date.now().toString().substring(0, 10),
        question: '',
        data_type: '',
      }],
      nextTodoId: 2,
    }
  },
  methods: {
    notifyParent() {
      this.$emit('input', this.items)
    },
    repeateAgain() {
      this.items.push({
        id: Date.now().toString().substring(0, 10),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
